import { useQuery } from '@tanstack/react-query';
import { AiOutlineWhatsApp } from 'react-icons/ai';
import { twMerge } from 'tailwind-merge';
import { Avatar } from '../../../../../components/Avatar';
import { oldIcons, VehicleIcon } from '../../../../../components/Icon/vehicles';
import { PageHeader } from '../../../../../components/PageHeader';
import { Table, TableActions } from '../../../../../components/Table/Table';
import { Tooltip } from '../../../../../components/Tooltip';
import WhiteContainer from '../../../../../components/WhiteContainer/WhiteContainer';
import {
	phoneMask,
	removePhoneMask,
} from '../../../../../helpers/mask/phoneMask';
import { useCopy } from '../../../../../hooks/useCopy';
import {
	CompanyModel,
	User,
} from '../../../../../services/angular/angularUserState';
import { api } from '../../../../../services/angular/axios';
import { Vehicle } from '../../../../../services/angular/types/Order';
import { toast } from 'react-toastify';
import { useAlert } from '../../../../../contexts/AlertContext';

interface CompanyTablesProps {
	data?: CompanyModel;
	onRefetch?: () => void; // ou Promise<void> se preferir
}

export const CompanyTables = ({ data, onRefetch }: CompanyTablesProps) => {
	const copy = useCopy();
	const alert = useAlert();
	const deleteCompanyUser = async (id: number) => {
		alert.onCustom({
			title: 'Excluir usuário',
			message: 'Deseja realmente excluir o usuário?',
			confirm: {
				label: 'Excluir',
				onClick: async () => {
					try {
						await api.delete(`/companies/${data?.id || ''}/users/${id}`);
						toast.success('Usuário excluído com sucesso');
						onRefetch?.();
					} catch (error) {
						toast.error('Erro ao excluir usuário');
					}
				},
			},
		});
	};

	const promoteUser = async (id: number) => {
		alert.onCustom({
			title: 'Alterar proprietário',
			message: 'Deseja realmente alterar o proprietário dessa empresa?',
			confirm: {
				label: 'Sim',
				onClick: async () => {
					try {
						await api.put(`/companies/set-owner`, {companyId: data?.id, ownerId: id});
						toast.success('Usuário excluído com sucesso');
						onRefetch?.();
					} catch (error) {
						toast.error('Erro ao excluir usuário');
					}
				},
			},
		});
	};
	if (!data) return null;

	return (
		<div className="mt-4 flex flex-col gap-4">
			<WhiteContainer>
				<PageHeader
					title="Usuários"
					description="Proprietário e relação de usuários da empresa"
				/>
				<Table<User>
					total={0}
					pagination={false}
					sort={false}
					data={
						data?.users
							? [data.owner, ...data.users]
							: data?.owner
							? [data.owner]
							: []
					}
					columns={[
						{
							Header: 'Nome',
							accessor: 'name',
							Cell: ({ row }) => {
								const { avatarUrl, name } = row.original;
								const { data: avatar } = useQuery(
									['avatar', avatarUrl],
									async () =>
										await api.get(
											avatarUrl?.substring(avatarUrl.indexOf('/companies'))
										),
									{
										enabled: !!avatarUrl,
									}
								);
								return (
									<div className="flex flex-row items-center gap-2">
										<Avatar
											avatarProps={{ className: 'w-10 h-10' }}
											name={name}
											src={avatar?.data.url}
										/>
										<div className="flex flex-col gap-1">
											<span>{row.original.name}</span>
											{row.original.id === data?.owner.id && (
												<span className="w-min rounded bg-orange/40 px-1 text-xs ring-1 ring-orange/60">
													Proprietário
												</span>
											)}
											{row.original.isAdmin && (
												<span className="w-min rounded bg-blue/20 px-1 text-xs ring-1 ring-blue/20">
													Admin
												</span>
											)}
										</div>
									</div>
								);
							},
						},
						{
							accessor: 'cpf',
							Header: 'CPF',
						},
						{
							accessor: 'cellphone',
							Header: 'Celular',
							Cell: ({ row }) => {
								const { cellphone, name } = row.original;
								return (
									<div className="flex gap-1">
										<span
											className="cursor-copy"
											onClick={() => {
												void copy(removePhoneMask(cellphone, '+55'));
											}}
										>
											{phoneMask(removePhoneMask(cellphone))}
										</span>
										<Tooltip message="Abir whatsapp">
											<a
												href={`https://api.whatsapp.com/send?phone=55${cellphone?.replace(
													/\D/g,
													''
												)}&text=Ol%C3%A1,%20${name}!`}
												target="_blank"
												rel="noreferrer"
											>
												<AiOutlineWhatsApp
													size={24}
													className="cursor-pointer text-green hover:text-primary-400"
												/>
											</a>
										</Tooltip>
									</div>
								);
							},
						},
						{
							accessor: 'blocked',
							Header: 'Acesso',
							Cell: ({ value }) => {
								return (
									<div className="flex text-sm leading-3">
										{value ? (
											<span className="rounded bg-terracota-500 px-2 py-1 text-white">
												Bloqueado
											</span>
										) : (
											<span className="rounded bg-green px-2 py-1 text-white">
												Desbloqueado
											</span>
										)}
									</div>
								);
							},
						},
						{
							accessor: 'created_at',
							Header: 'Ações',
							width: '0%',
							Cell: ({ row }) => {
								const user = row.original;
								const isOwnerAndNotAdmin = user.id === data?.owner?.id && !user.isAdmin;
								const isOwner = user.id === data?.owner?.id;
								// 1. Crie o array vazio
								const actions = [];
							  
								// 2. Adicione o primeiro objeto
								actions.push({
									icon: 'edit',
									label: 'Editar',
									href: `/usuarios/${user.id}`,
								});
							  
								// 3. Se não for proprietário, adicione o de "Excluir"
								if (!isOwnerAndNotAdmin) {
									actions.push({
										icon: 'trash',
										label: 'Excluir',
										onAction: (d: User) => {
											void deleteCompanyUser(d.id);
										},
									});
								}
							  
								// 4. Adicione o item de "Definir como proprietário"
								if (!isOwner) {
									actions.push({
									icon: 'user',
									label: 'Definir como proprietário',
									onAction: (d: User) => {
										void promoteUser(d.id);
									},
									});
								}
							  
								return <TableActions row={row} actions={actions} />;
							  },
						},
					]}
				/>
			</WhiteContainer>
			{!!data?.drivers?.length && (
				<WhiteContainer>
					<PageHeader
						title="Motoristas"
						description="Relação de Motoristas da empresa"
					/>
					<Table<User>
						total={0}
						pagination={false}
						sort={false}
						data={data?.drivers || []}
						columns={[
							{
								Header: 'Nome',
								accessor: 'name',
								Cell: ({ row }) => {
									const { avatarUrl, name } = row.original;
									const { data: avatar } = useQuery(
										['avatar', avatarUrl],
										async () =>
											await api.get(
												avatarUrl?.substring(avatarUrl.indexOf('/companies'))
											),
										{
											enabled: !!avatarUrl,
										}
									);
									return (
										<div className="flex flex-row items-center gap-2">
											<Avatar
												avatarProps={{ className: 'w-10 h-10' }}
												name={name}
												src={avatar?.data.url}
											/>
											{name}
										</div>
									);
								},
							},
							{
								accessor: 'cpf',
								Header: 'CPF',
							},
							{
								accessor: 'cellphone',
								Header: 'Celular',
								Cell: ({ row }) => {
									const { cellphone, name } = row.original;
									return (
										<div className="flex gap-1">
											<span
												className="cursor-copy"
												onClick={() => {
													void copy(removePhoneMask(cellphone, '+55'));
												}}
											>
												{phoneMask(removePhoneMask(cellphone))}
											</span>
											<Tooltip message="Abir whatsapp">
												<a
													href={`https://api.whatsapp.com/send?phone=55${cellphone?.replace(
														/\D/g,
														''
													)}&text=Ol%C3%A1,%20${name}!`}
													target="_blank"
													rel="noreferrer"
												>
													<AiOutlineWhatsApp
														size={24}
														className="cursor-pointer text-green hover:text-primary-400"
													/>
												</a>
											</Tooltip>
										</div>
									);
								},
							},
							{
								accessor: 'blocked',
								Header: 'Acesso',
								Cell: ({ value }) => {
									return (
										<div className="flex text-sm leading-3">
											{value ? (
												<span className="rounded bg-terracota-500 px-2 py-1 text-white">
													Bloqueado
												</span>
											) : (
												<span className="rounded bg-green px-2 py-1 text-white">
													Desbloqueado
												</span>
											)}
										</div>
									);
								},
							},
							{
								accessor: 'created_at',
								Header: 'Ações',
								width: '0%',
								Cell: ({ row }) => (
									<TableActions
										row={row}
										actions={[
											{
												icon: 'edit',
												label: 'Editar',
												href: `/usuarios/${row.original.id}`,
											},
										]}
									/>
								),
							},
						]}
					/>
				</WhiteContainer>
			)}
			{data.hasFavoriteDrivers && (
				<WhiteContainer>
					<PageHeader
						title="Motoristas favoritos"
						description="Relação de Motoristas favoritos da empresa"
					/>
					<Table<User>
						total={0}
						pagination={false}
						sort={false}
						data={data?.favoriteDrivers || []}
						columns={[
							{
								Header: 'Nome',
								accessor: 'name',
								Cell: ({ row }) => {
									const { avatarUrl, name } = row.original;
									const { data: avatar } = useQuery(
										['avatar', avatarUrl],
										async () =>
											await api.get(
												avatarUrl?.substring(avatarUrl.indexOf('/companies'))
											),
										{
											enabled: !!avatarUrl,
										}
									);
									return (
										<div className="flex flex-row items-center gap-2">
											<Avatar
												avatarProps={{ className: 'w-10 h-10' }}
												name={name}
												src={avatar?.data.url}
											/>
											{name}
										</div>
									);
								},
							},
							{
								accessor: 'cpf',
								Header: 'CPF',
							},
							{
								accessor: 'cellphone',
								Header: 'Celular',
								Cell: ({ row }) => {
									const { cellphone, name } = row.original;
									return (
										<div className="flex gap-1">
											<span
												className="cursor-copy"
												onClick={() => {
													void copy(removePhoneMask(cellphone, '+55'));
												}}
											>
												{phoneMask(removePhoneMask(cellphone))}
											</span>
											<Tooltip message="Abir whatsapp">
												<a
													href={`https://api.whatsapp.com/send?phone=55${cellphone?.replace(
														/\D/g,
														''
													)}&text=Ol%C3%A1,%20${name}!`}
													target="_blank"
													rel="noreferrer"
												>
													<AiOutlineWhatsApp
														size={24}
														className="cursor-pointer text-green hover:text-primary-400"
													/>
												</a>
											</Tooltip>
										</div>
									);
								},
							},
							{
								accessor: 'blocked',
								Header: 'Acesso',
								Cell: ({ value }) => {
									return (
										<div className="flex text-sm leading-3">
											{value ? (
												<span className="rounded bg-terracota-500 px-2 py-1 text-white">
													Bloqueado
												</span>
											) : (
												<span className="rounded bg-green px-2 py-1 text-white">
													Desbloqueado
												</span>
											)}
										</div>
									);
								},
							},
							{
								accessor: 'created_at',
								Header: 'Ações',
								width: '0%',
								Cell: ({ row }) => (
									<TableActions
										row={row}
										actions={[
											{
												icon: 'edit',
												label: 'Editar',
												href: `/usuarios/${row.original.id}`,
											},
										]}
									/>
								),
							},
						]}
					/>
				</WhiteContainer>
			)}
			{!!data?.vehicles?.length && (
				<WhiteContainer>
					<PageHeader
						title="Veículos"
						description="Relação de veículos da empresa"
					/>
					<Table<Vehicle>
						total={0}
						pagination={false}
						sort={false}
						data={data?.vehicles || []}
						columns={[
							{
								accessor: 'categoryId',
								Header: 'Categoria',
								Cell: ({ row }) => {
									const { category, featureOneActive, featureTwoActive } =
										row.original;
									return (
										<div className="flex gap-2">
											<VehicleIcon
												/* @ts-expect-error */
												name={oldIcons[category.icon]}
												size={36}
											/>
											<div>
												{category.name}
												{(featureOneActive || featureTwoActive) && (
													<div className="text-xs text-neutral-600">
														{featureOneActive
															? category.typeOneDescription
															: featureTwoActive
															? category.typeTwoDescription
															: ''}
													</div>
												)}
											</div>
										</div>
									);
								},
							},
							{
								accessor: 'plate',
								Header: 'Placa',
								Cell: ({ value }) => {
									return (
										<div className="flex w-min rounded-md border p-1 text-xs">
											{value}
										</div>
									);
								},
							},
							{
								accessor: 'available',
								Header: 'Disponibilidade',
								Cell: ({ row }) => {
									const { activeOrders, available, currentDriver } =
										row.original;

									const tooltip = currentDriver
										? available
											? currentDriver.online
												? {
														message: `Veículo com motorista online`,
														className: 'bg-green',
												  }
												: {
														message: `Veículo com motorista offline`,
														className: 'bg-blue',
												  }
											: {
													message: `Entregando pedido ${activeOrders?.[0]?.id}`,
													className: 'bg-yellow-500',
											  }
										: {
												message: `Veículo sem motorista`,
												className: 'bg-terracota-500',
										  };

									return (
										<Tooltip message={tooltip.message}>
											<div
												className={twMerge(
													'h-4 w-4 rounded-full shadow-inner shadow-neutral-700',
													tooltip.className
												)}
											/>
										</Tooltip>
									);
								},
							},
							{
								Header: 'Status',
								accessor: 'status',
								Cell: ({ row }) => {
									const { status } = row.original;

									const statusObj =
										status === 1
											? {
													message: `Aguardando aprovação`,
													className: 'bg-orange',
											  }
											: status === 2
											? {
													message: `Rejeitado`,
													className: 'bg-terracota-500',
											  }
											: {
													message: `Aprovado`,
													className: 'bg-green',
											  };

									return (
										<div
											className={twMerge(
												'w-min rounded px-1.5 py-1 text-center text-xs leading-3 text-white',
												statusObj.className
											)}
										>
											{statusObj.message}
										</div>
									);
								},
							},
							{
								accessor: 'id',
								Header: 'Ações',
								width: '0%',
								Cell: ({ row }) => (
									<TableActions
										row={row}
										actions={[
											{
												icon: 'edit',
												label: 'Editar',
												href: `/veiculos/${row.original.id}`,
											},
										]}
									/>
								),
							},
						]}
					/>
				</WhiteContainer>
			)}
		</div>
	);
};
