import { PageHeader } from '../../../../components/PageHeader';
import { IFoodSettings } from './components/settings';

export const IFoodSettingsPage = () => {
	return (
		<div className="w-full text-neutral-900">
			<PageHeader title="Configurações Ifood" />
			<div className="flex flex-col gap-4">
				<IFoodSettings />
			</div>
		</div>
	);
};
