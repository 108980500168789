// IFoodOrdersPage.tsx
import { PageHeader } from '../../../../components/PageHeader';
import { IFoodOrders } from './components/orders';
import { IFoodInProgress } from './components/inProgress';

export const IFoodOrdersPage = () => {
  return (
    <div className="w-full text-neutral-900">
      <PageHeader title="Painel Ifood" description="Gerenciador de pedidos IFood" />
      {/* Exibe os componentes lado a lado em telas grandes */}
      <div className="flex flex-col lg:flex-row gap-4">
        <div className="flex-1">
          <IFoodOrders />
        </div>
        <div className="flex-1">
          <IFoodInProgress />
        </div>
      </div>
    </div>
  );
};
