/* eslint-disable @typescript-eslint/no-misused-promises */
import { Table, TableActions } from '../../../../components/Table/Table';
import { Tooltip } from '../../../../components/Tooltip';
// import { useGetFreights } from '../../../../services/angular/useOrders';
import { TbClock, TbMap2, TbTruckDelivery } from 'react-icons/tb';
import dayjs from 'dayjs';
import { Row } from 'react-table';
import { Driver } from '../../../../components/Angular/FreightsDetail/Driver';
import useUserState from '../../../../services/angular/angularUserState';
import { SortOptions } from '../../../../services/angular/types/Params';
import { useEffect, useMemo, useState } from 'react';
import { Distance } from '../Historic/Distance';
import {
	ProgressFilterSchema,
	ProgressFilterSchemaType,
} from './schema/ProgressFilterSchema';
import {
	Date,
	Input,
	Select,
	useZodForm,
} from '../../../../components/FormElements';
import { PageHeader } from '../../../../components/PageHeader';
import { FiltersTab } from '../../../../components/FiltersTab';
import WhiteContainer from '../../../../components/WhiteContainer/WhiteContainer';
import { FaExclamationCircle, FaShare } from 'react-icons/fa';
import { twMerge } from 'tailwind-merge';
import { OrderResponseApi } from '../../../../services/angular/types/Order';
import { CancelOrderModal } from '../../../../components/Order/CancelOrderModal';
import { AiFillStar } from 'react-icons/ai';
import RouteIcon from '../../../../components/RouteIcon/RouteIcon';
import { dateFormat } from '../../../../helpers/format/dateFormat';
import {
	getCurrentPendingLocation,
	hasCheckin,
	isInCourse,
} from '../../../../helpers/utils';
import { CategoryType } from '../../../../components/Order/CategoryType';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import { api } from '../../../../services/angular/axios';
import { useQuery } from '@tanstack/react-query';

export const Progress = () => {
	const company = useUserState((state) => state.company);
	const [query, setQuery] = useState<ProgressFilterSchemaType>();

	const [cancelModalReason, setCancelModalReason] = useState(false);
	const [orderToCancelId, setOrderToCancelId] = useState<number>();

	const [params, setParams] = useState<{
		page: number;
		take: number;
		sortBy?: SortOptions;
	}>({
		page: 1,
		take: 10,
	});

	// const { data, isLoading, isFetching } = useGetFreights(
	// 	{
	// 		companyId: company?.id,
	// 		companyType: company?.type,
	// 		status: 'progress',
	// 		query,
	// 		...params,
	// 	},
	// 	{
	// 		refetchOnMount: 'always',
	// 	}
	// );
	const { data } = useQuery(
		[
		  'freights',
			{
				companyId: company?.id,
				companyType: company?.type,
				status: 'progress',
				query,
				...params,
			},
		],
		async () =>
		  // Se não tiver companyId ou companyType, nem chama a API.
			!company?.id || !company?.type
			? await Promise.resolve()
				: await api.get(
					`/orders/schedules/companies/${company?.id}?include=locations,locations.protocols,incident,status,paymentType,requesterCompany,requester,driver,driverCompany,category,integrationOrder`,
					{
						params: {
							status: 'progress',
							profile: company?.type,
							...query,
							...params,
						},
					}
				),
			{
				refetchOnMount: 'always',
				refetchOnReconnect: true, // Quando reconectar, faz refetch
				refetchInterval: 10000,    // Faz polling a cada 10 segundos
				// Se quiser atualizar ao focar a tela, inclua:
				refetchOnWindowFocus: true,
			}
	  	);


	const form = useZodForm({
		schema: ProgressFilterSchema,
	});

	return (
		<div>
			<PageHeader
				title="Fretes em andamento"
				description="Fretes aguardando motorista ou que já estejam em curso"
			/>

			<FiltersTab
				form={form}
				clearFilters={() => {
					form.reset();
					setQuery(undefined);
				}}
				onSubmit={(data) => {
					setQuery(data);
				}}
			>
				<Date
					variant="light"
					className="w-full md:w-auto"
					control={form.control}
					name="initialDate"
					label="De"
				/>
				<Date
					variant="light"
					className="w-full md:w-auto"
					control={form.control}
					name="endDate"
					label="Até"
				/>
				<Select
					variant="light"
					className="w-full md:w-auto md:min-w-[128px]"
					label="Data"
					controller={{
						control: form.control,
						name: 'operation',
						defaultValue: null,
					}}
					options={[
						{
							label: 'Imediato',
							value: 'immediate',
						},
						{
							label: 'Agendado',
							value: 'scheduled',
						},
					]}
				/>
				<Select
					variant="light"
					className="w-full md:w-auto md:min-w-[128px]"
					label="Tipo"
					controller={{
						control: form.control,
						name: 'orderTypeId',
						defaultValue: null,
					}}
					options={[
						{
							label: 'Por hora',
							value: '3',
						},
						{
							label: 'Rota fixa',
							value: '1',
						},
						{
							label: 'Rota livre',
							value: '2',
						},
					]}
				/>
				<Input
					variant="light"
					label="Pesquisar"
					className="w-full md:w-auto"
					{...form.register('query')}
				/>
			</FiltersTab>
			<WhiteContainer>
				<Table<OrderResponseApi>
					triggerPageChange={form.formState.isSubmitting}
					// isLoading={isLoading || isFetching}
					columns={[
						{
							accessor: 'id',
							Header: 'ID',
							Cell: ({ row }) => {
								const { id, searchOnlyFavoriteDrivers, integrationOrder } = row.original;

								return (
									<div className="flex flex-col">
										<div className="flex flex-row">
										{searchOnlyFavoriteDrivers && (
											<Tooltip
												message="Somente motoristas favoritos"
												triggerClassName="self-start px-2"
											>
												<AiFillStar className="text-yellow-submenu" size={22} />
											</Tooltip>
										)}
										{integrationOrder && (
												<Tooltip
													message="Pedido de integração"
													triggerClassName="flex flex-row self-start gap-x-2"
												>
													<BsFillInfoCircleFill size={18} />
													{integrationOrder?.displayId}
												</Tooltip>
											)}
										</div>
										<p className="text-lg font-bold text-orange">
											#{String(id).padStart(5, '0')}
										</p>
									</div>
								);
							},
						},
						{
							accessor: 'orderDate',
							Header: 'Data',
							Cell: ({ row }) => {
								const { orderDate, scheduleDate } = row.original;
								const date = dayjs(orderDate).format('DD/MM/YYYY HH:mm');

								return (
									<div className="flex gap-1">
										{scheduleDate ? (
											<TbClock size={30} />
										) : (
											<TbTruckDelivery size={30} />
										)}
										<div className="flex flex-col">
											<span className="font-bold text-neutral-800">
												{scheduleDate ? 'Agendado' : 'Imediato'}
											</span>
											<span className="text-sm text-neutral-500">{date}</span>
										</div>
									</div>
								);
							},
						},
						{
							accessor: 'completionPrevisionDate',
							Header: 'Prev. término',
							Cell: ({ value }) => {
								const date = dayjs(value).format('DD/MM/YYYY HH:mm');
								return (
									<div className="flex flex-col text-sm">
										<span>{date}</span>
										<span className="text-xs text-neutral-500">
											Previsão de términio
										</span>
									</div>
								);
							},
						},
						{
							// @ts-expect-error
							accessor: 'driverCompany.id',
							Header: 'Motorista',
							Cell: ({ row }: { row: Row<OrderResponseApi> }) => {
								return (
									<div className="flex">
										{row.original.driver && row.original.driverCompany ? (
											<Driver
												orderId={row.original.id}
												driver={row.original.driver}
												driverCompany={row.original.driverCompany}
											>
												<div className="flex flex-col items-start">
													<div className="line-clamp-2 text-left font-medium leading-none">
														{row.original.driver.name}
													</div>
													<span className="mt-1 text-xs text-neutral-600">
														Fretista
													</span>
												</div>
											</Driver>
										) : (
											<div className="flex flex-col">-</div>
										)}
									</div>
								);
							},
						},
						{
							accessor: 'categoryId',
							Header: 'Veículo',
							Cell: ({ row }) => (
								<CategoryType
									category={row.original.category}
									categoryType={row.original.categoryType}
									isFavoriteDriver={row.original.isFavoriteDriver}
									defrauded={row.original.defrauded}
								/>
							),
						},
						{
							accessor: 'distance',
							Header: 'Distância',
							Cell: ({ row }) => <Distance order={row.original} />,
						},
						{
							accessor: 'locations',
							Header: 'Próximo ponto',
							Cell: ({ row }) => {
								const { locations, acceptanceDate, scheduleDate } =
									row.original;

								const [diff, setDiff] = useState(
									dayjs().diff(scheduleDate || acceptanceDate, 'minute')
								);

								const pendingLocation = getCurrentPendingLocation(row.original);

								const showDelay = useMemo(
									() =>
										locations?.[0] &&
										!hasCheckin(locations[0]) &&
										isInCourse(row.original),
									[pendingLocation]
								);

								useEffect(() => {
									if (!showDelay) return;
									const interval = setInterval(() => {
										const diff = dayjs().diff(
											scheduleDate || acceptanceDate,
											'minute'
										);
										setDiff(diff);
									}, 60 * 1000); // 1 minute
									return () => clearInterval(interval);
								}, []);

								const delay = useMemo(
									() =>
										dayjs(scheduleDate || acceptanceDate)
											.locale('pt-br')
											.fromNow(true),
									[diff]
								);

								return (
									<div
										className={twMerge(
											'flex flex-col p-1',
											showDelay && 'rounded bg-terracota-0 text-white'
										)}
									>
										{pendingLocation ? (
											<div className="flex">
												<RouteIcon
													number={
														pendingLocation ? pendingLocation?.sequence + 1 : 1
													}
													checkoutDate={pendingLocation?.checkoutDate}
													checkinDate={pendingLocation?.checkinDate}
													reasonDate={pendingLocation?.reasonDate}
												/>
												<div className="flex min-w-[5rem] flex-col text-xs">
													{!pendingLocation.checkinDate && (
														<b>
															Motorista <br /> a caminho
														</b>
													)}

													{pendingLocation.checkinDate &&
														!pendingLocation.checkoutDate &&
														!pendingLocation.reasonDate && (
															<div className="flex flex-col text-xs">
																<span className="font-semibold">
																	Checkin -{' '}
																	{pendingLocation
																		? pendingLocation?.sequence + 1
																		: 1}
																	/{locations.length}
																</span>
																<span>
																	{dateFormat(pendingLocation?.checkinDate)}
																</span>
															</div>
														)}

													{pendingLocation.checkoutDate &&
														!pendingLocation.reasonDate && (
															<div className="flex flex-col text-xs">
																<span className="font-semibold">
																	Checkout -{' '}
																	{pendingLocation
																		? pendingLocation?.sequence + 1
																		: 1}
																	/{locations.length}
																</span>
																<span>
																	{dateFormat(pendingLocation?.checkoutDate)}
																</span>
															</div>
														)}

													{pendingLocation.reasonDate && (
														<div className="flex flex-col text-xs">
															<span className="font-semibold">
																Não entregue -{' '}
																{pendingLocation
																	? pendingLocation?.sequence + 1
																	: 1}
																/{locations.length}
															</span>
															<span>
																{dateFormat(pendingLocation?.reasonDate)}
															</span>
														</div>
													)}
												</div>
											</div>
										) : (
											<div className="text-start text-xs font-semibold">-</div>
										)}
										{showDelay ? (
											<span className="pt-1 text-start text-xs font-semibold">
												Atrasado para coleta <br /> há {delay}
											</span>
										) : (
											<span className="pt-1 text-start text-xs font-semibold">
												Próxima parada
											</span>
										)}
									</div>
								);
							},
						},
						{
							accessor: 'total',
							Header: 'Valor',
							Cell: ({ row }) => {
								const { total, delivery, driverSubTotal } = row.original;
								return (
									<div className="flex flex-col">
										<span
											className={twMerge(
												'text-sm',
												delivery ? 'text-green' : 'text-terracota-500'
											)}
										>
											{delivery
												? driverSubTotal.toLocaleString('pt-br', {
														style: 'currency',
														currency: 'BRL',
												  })
												: total.toLocaleString('pt-br', {
														style: 'currency',
														currency: 'BRL',
												  })}
										</span>
									</div>
								);
							},
						},
						{
							accessor: 'created_at',
							Header: 'Ações',
							width: '0%',
							Cell: ({ row }) => (
								<TableActions
									row={row}
									actions={[
										{
											icon: <FaExclamationCircle size={16} />,
											label: 'Detalhes',
											href: `/fretes/detalhes/${row.original.id}`,
										},
										{
											icon: 'trash',
											label: 'Cancelar pedido',
											onAction: (data) => {
												setCancelModalReason(true);
												setOrderToCancelId(data.id);
											},
										},
										{
											icon: <FaShare size={16} />,
											label: 'Pedir novamente',
											href: `/fretes/pedir?ref=${row.original.id}`,
										},
										{
											icon: <TbMap2 size={16} />,
											label: 'Acompanhar pedido',
											href: `/fretes/acompanhar/${row.original.id}`,
										},
									]}
								/>
							),
						},
					]}
					disabledSort={{
						created_at: true,
						locations: true,
					}}
					data={data?.data.data || []}
					total={data?.data.meta.pagination.total || 0}
					onParamsChange={(params) => {
						setParams(params);
					}}
				/>
				<CancelOrderModal
					open={cancelModalReason}
					setOpen={setCancelModalReason}
					orderId={orderToCancelId}
				/>
			</WhiteContainer>
		</div>
	);
};

export default Progress;
