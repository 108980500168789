/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { IoClose } from 'react-icons/io5';
import {
	IFoodOrder,
	useGetIfoodOrders,
} from '../../../../../services/integration/ifood/useGetIfoodOrders';
import { Checkbox, SwitchButton } from '../../../../../components/FormElements';
import dayjs from 'dayjs';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { integrationApi } from '../../../../../services/angular/axios';
import useUserState from '../../../../../services/angular/angularUserState';
import Button from '../../../../../components/Button/Button';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import {
	DndContext,
	closestCenter,
	useSensor,
	useSensors,
	DragEndEvent,
	PointerSensor,
} from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import { arrayMove, SortableContext, useSortable } from '@dnd-kit/sortable';
import { Modal } from '../../../../../components/Modal';
import { useAlert } from '../../../../../contexts/AlertContext';
import { ToastError } from '../../../../../helpers/errors/ToastError';

const limitOrders = 30;

/**
 * ##############
 * 1) HOOK useCreateOrder
 * ##############
 */
interface CreateOrderData {
	companyId: number;
	orders: Array<{ orderId: string }>;
	optimize: boolean;
	submit: boolean;
}

interface Response {
	orderId: number;
	id: number;
	externalOrderId: string;
	status: Status;
	price: string;
	forecast?: any;
	trackingUrl?: any;
	integrationCompany: IntegrationCompany;
	locations: Location[];
}

interface Status {
	id: number;
	name: string;
}

interface IntegrationCompany {
	companyName: string;
	tradingName: string;
	avatarUrl?: any;
	phone?: any;
	cellphone: string;
}

interface Location {
	id: number;
	sequence: number;
	latitude: number;
	longitude: number;
	checkinDate?: any;
	checkoutDate?: any;
	reasonDate?: any;
	checkoutNotes?: any;
	reasonId?: any;
	reason?: any;
	receiverName?: any;
	receiverLastName?: any;
}

const useCreateOrder = (onSuccessCallback?: () => void) => {
	const navigate = useNavigate();
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (data: CreateOrderData) => {
			// Note que o 'submit' também está sendo passado para o backend;
			// adapte se necessário, caso a API não exija esse campo.
			return await integrationApi.post<Response>('/ifood/orders', data);
		},
		onSuccess: (data, variables) => {
			// Se for um “submit” de fato, podemos redirecionar.
			// Caso contrário, pode-se mudar a lógica conforme o que for necessário:
			if (variables.submit) {
				toast.success('Pedido lançado com sucesso!');
			} else {
				toast.success('Pedido criado com sucesso.');
				navigate(`/fretes/detalhes/${data?.data.id || data?.data.orderId}`);

			}
			onSuccessCallback?.();
			void queryClient.invalidateQueries({
				queryKey: ['ifood-orders'],
				exact: false,
			});
		},
		onError: (error: any) => {
			ToastError(error);
		},
	});
};

/**
 * ##############
 * 2) Componente principal IFoodOrders
 * ##############
 */
export const IFoodOrders = () => {
	const [open, setOpen] = useState(false);
	const { data } = useGetIfoodOrders();
	const [checkedRows, setCheckedRows] = useState<IFoodOrder[]>([]);
	const { mutate: createOrder, isLoading: isCreatingOrder } = useCreateOrder(() => {
		setCheckedRows([]);
	});

	const companyId = useUserState((state) => state.company?.id);

	const handleCheckboxChange = (order: IFoodOrder) => {
		if (
			checkedRows.find((checkedOrder) => checkedOrder.orderId === order.orderId)
		) {
			setCheckedRows(
				checkedRows.filter(({ orderId }) => orderId !== order.orderId)
			);
			return true;
		}

		if (checkedRows.length < limitOrders) {
			setCheckedRows([...checkedRows, order]);
			return true;
		}

		toast.update('max-orders', {
			render: `Você pode selecionar no máximo ${limitOrders} pedidos por vez`,
		});
		toast.info(
			`Você pode selecionar no máximo ${limitOrders} pedidos por vez`,
			{
				toastId: 'max-orders',
			}
		);

		return false;
	};

	return (
		<div className="flex flex-col gap-4 rounded-md bg-white px-8 py-4 w-full">
			<div className="flex justify-center">
			<h1 className="text-lg font-bold text-neutral-800 ">
					Pedidos iFood - {data?.length}
				</h1>
			</div>
			<div className="flex justify-center gap-8 ">
				{/* BOTÃO LANÇAR PEDIDO (submit = true) */}
				{Boolean(checkedRows.length) && (
					<Button
						variant="blue-primary"
						className="w-auto min-w-fit px-1.5 py-0.5"
						loading={isCreatingOrder}
						disabled={isCreatingOrder}
						onClick={() =>
							createOrder({
								companyId: companyId!,
								orders: checkedRows.map(({ orderId }) => ({ orderId })),
								optimize: true, // ou true, se quiser
								submit: true, // <<< AQUI definimos “submit=true”
							})
							
						}
					>
						Lançar pedido
					</Button>
				)}

				{/* BOTÃO VISUALIZAR PEDIDO (abre o modal) */}
				{Boolean(checkedRows.length) && (
					<Button
						variant="blue-primary"
						className="w-auto min-w-fit px-1.5 py-0.5"
						onClick={() => setOpen(true)} // apenas abre o modal
					>
						Visualizar pedido
					</Button>
				)}
				</div>
			<div className="flex w-full flex-col gap-2">
				<div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
					{data?.map((order) => (
						<Card
							key={order.orderId}
							order={order}
							onSelect={handleCheckboxChange}
						/>
					))}
				</div>
			</div>

			{/* Modal para reordenar e criar com submit=false */}
			<Modal
				setOpen={setOpen}
				open={open}
				title="Pedidos selecionados"
				headless
				className="max-w-5xl"
			>
				<CreatedOrder
					data={checkedRows.map((order) => ({ ...order, id: order.orderId }))}
				/>
			</Modal>
		</div>
	);
};

/**
 * ##############
 * 3) Componente CreatedOrder (dentro do modal)
 * ##############
 */
const CreatedOrder = ({
	data,
}: {
	data: Array<IFoodOrder & { id: string }>;
}) => {
	const [optimize, setOptimize] = useState(true);
	const companyId = useUserState((state) => state.company?.id);
	const sensors = useSensors(useSensor(PointerSensor));
	const [items, setItems] = useState(data);

	// Hook para criar pedido
	const { mutate, isLoading } = useCreateOrder();

	useEffect(() => {
		setItems(data);
	}, [data]);

	const handleDragEnd = (event: DragEndEvent) => {
		const { active, over } = event;
		if (active.id !== over?.id) {
			setItems((prev) => {
				const oldIndex = prev.findIndex((item) => item.id === active.id);
				const newIndex = prev.findIndex((item) => item.id === over?.id);
				return arrayMove(prev, oldIndex, newIndex);
			});
		}
	};

	return (
		<div className="flex flex-col gap-4">
			<p className="mr-8">
				Caso deseje alterar a ordem dos pedidos, basta arrastar e soltar. Em
				caso de retorno a rota será otimizada.
			</p>
			<div className="flex items-center gap-2">
				<span>Otimizar rota automaticamente</span>
				<SwitchButton
					checked={optimize}
					onChange={() => setOptimize((prev) => !prev)}
				/>
			</div>
			<div className="grid grid-cols-2 gap-2 lg:grid-cols-3 xl:grid-cols-4">
				<DndContext
					sensors={sensors}
					collisionDetection={closestCenter}
					onDragEnd={handleDragEnd}
				>
					<SortableContext items={items}>
			
						{items.map((item, index) => (
							<SortableItem key={item.id} order={item} index={index + 1} />
						))}
				
					</SortableContext>
				</DndContext>
			</div>

			{/* Ao clicar aqui, criamos o pedido com submit=false */}
			<Button
				variant="blue-primary"
				onClick={() =>
					mutate({
						companyId: companyId!,
						orders: items.map(({ orderId }) => ({ orderId })),
						optimize,
						submit: false, // <<< AQUI definimos “submit=false”
					})
				}
				loading={isLoading}
				disabled={isLoading}
			>
				Criar pedido
			</Button>
		</div>
	);
};

interface Props {
	order: IFoodOrder & { id: string };
	index: number;
}

const SortableItem = ({ order, index }: Props) => {
	const { attributes, listeners, setNodeRef, transform, transition } =
		useSortable({ id: order.id });

	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
	};

	return (
		<div
			className="w-full"
			ref={setNodeRef}
			style={style}
			{...attributes}
			{...listeners}
		>
			<Card order={order} index={index} />
		</div>
	);
};

/**
 * ##############
 * 4) Componente Card
 * ##############
 */
const Card = ({
	order,
	onSelect,
	index,
}: {
	order: IFoodOrder;
	onSelect?: (order: IFoodOrder) => boolean;
	index?: number;
}) => {
	const queryClient = useQueryClient();
	const companyId = useUserState((state) => state.company?.id);
	const alert = useAlert();
	const [checked, setChecked] = useState(false);
	const {
		companyName,
		displayId,
		delivery: { deliveryAddress },
		orderTiming,
		deliveryStartTime,
		merchant,
	} = order;

	const { mutate } = useMutation({
		mutationFn: async (data: { companyId: number; orderId: string }) =>
			await integrationApi.post('/ifood/orders/ignore', data),
		onSuccess: () => {
			void queryClient.invalidateQueries({
				queryKey: ['ifood-orders'],
				exact: false,
			});
			toast.success('Pedido ignorado com sucesso!');
		},
	});

	const handleCheckboxChange = () => {
		if (!onSelect) return;
		const val = onSelect(order);
		// Se a função `onSelect` retornou true, podemos alterar o estado do check
		if (val) setChecked((prev) => !prev);
	};

	const handleDelete = () => {
		alert.onCustom({
			title: 'Ignorar pedido',
			message: `Deseja realmente ignorar o pedido ${order.displayId}?`,
			confirm: {
				label: 'Limpar',
				onClick: async () => {
					mutate({
						orderId: order.orderId,
						companyId: companyId!,
					});
				},
			},
		});
	};

	return (
		<div
		className="flex w-full flex-col rounded-md border border-gray-200 bg-white p-4 shadow-sm"
		onClick={handleCheckboxChange}
	>
			<div className="flex items-start gap-3">
				{onSelect && (
					<div className="flex justify-center flex-col gap-2">
					<Checkbox label="" checked={checked} />
					<button
						className="flex h-5 w-5 items-center justify-center rounded bg-red text-white hover:opacity-80"
						onClick={(e) => {
							e.stopPropagation();
							handleDelete();
						}}
					>
						<IoClose size={16} />
					</button>
					</div>
				)}
				{index && <div className="mb-2 font-bold">{index}</div>}
		
				<div className="flex-1">
					<div className="text-md font-bold mb-1">
						#{displayId}
					</div>

					<div className="mb-2">
						{orderTiming === 'IMMEDIATE' && <div className="font-medium">Imediato</div>}
						{orderTiming === 'SCHEDULED' && (
							<>
								<div className="text-sm font-medium">Agendado</div>
								{dayjs(deliveryStartTime).format('DD/MM/YYYY - HH:mm')}
							</>
						)}
					</div>
		
					<div className="text-sm text-gray-600">
						<span className="font-medium">Merchant:</span> {merchant.name} 
					</div>
					{/* Endereço */}
					<div className="text-sm text-gray-600">
						<div>
							<span className="font-medium">Endereço:</span> {deliveryAddress.formattedAddress}
						</div>
						<div>
							<span className="font-medium">Bairro:</span> {deliveryAddress.neighborhood} |{' '}
							<span className="font-medium">Cidade:</span> {deliveryAddress.city}
						</div>
					</div>

					{/* Pagamento */}
					<div className="mt-2 text-sm font-semibold text-red-600">
						{order.paymentsMethods.map((payment, index) => (
							<span key={index}>
								{payment.type === 'ONLINE' ? (
									<p>Pago via iFood</p>
								) : (
									<>
										{payment.card && (
											<p>
												{payment.method} - {payment.card.brand}
											</p>
										)}
										{payment.cash && (
											<p>
												Troco para:{' '}
												{payment.cash.changeFor.toLocaleString('pt-BR', {
													style: 'currency',
													currency: 'BRL',
												})}
											</p>
										)}
									</>
								)}
							</span>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};
