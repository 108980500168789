import React, { useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { api } from '../services/angular/axios';
import {
	Geofence,
	appState,
	appStateStore,
	useAppState,
} from '../store/appState';
import useUserState from '../services/angular/angularUserState';
import { useStore } from 'zustand';
import { shallow } from 'zustand/shallow';
import { atom, useAtom } from 'jotai';
import { useQuery } from '@tanstack/react-query';
import { RequirePermission } from './RequirePermission';
import PublicLayout from '../layout/PublicLayout';
import AdminLayout from '../layout/AdminLayout';
import OrdersPage from '../pages/Angular/OperationPages/OrdersPage/ordersPage';
import { Follow } from '../pages/Angular/Freights/Follow';
import { Draft } from '../pages/Angular/Freights/Draft';
import { EditOrder } from '../pages/Angular/OperationPages/EditOrder';
import Monitoring from '../pages/Angular/OperationPages/Monitoring/monitoring';
import Earnings from '../pages/Angular/OperationPages/Finance/Earnings';
import Charges from '../pages/Angular/OperationPages/Finance/Charges';
import { EditUser } from '../pages/Angular/OperationPages/Users/EditUser';
import { Vehicles } from '../pages/Angular/OperationPages/Vehicles';
import AddVehicle from '../pages/Angular/Companies/Vehicles/components/AddVehicle';
import Companies from '../pages/Angular/OperationPages/Companies/Companies';
import AngularNewCompanyRegister from '../pages/Angular/Companies/CreateCompany';
import Notifications from '../pages/Angular/OperationPages/Administration/Notifications/Notifications';
import InformativePopUp from '../pages/Angular/OperationPages/Administration/InformativePopUp/InformativePopUp';
import {
	canBuyCredits,
	canManageCompany,
	canManageCompanyDrivers,
	canRequestFreight,
	canSeeDashboard,
	canSeeFavorites,
	canSeeReceipt,
	canSeeWallet,
	isSuperAdmin,
} from '../helpers/permission';
import Audit from '../pages/Angular/OperationPages/Administration/Audit/Audit';
import Transactions from '../pages/Angular/OperationPages/Administration/Transaction/Transaction';
import { ConfigVehicles } from '../pages/Angular/OperationPages/Settings/Categories';
import { EditCategory } from '../pages/Angular/OperationPages/Settings/Categories/EditCategory';
import { ConfigSystem } from '../pages/Angular/OperationPages/System';
import OrderDetails from '../pages/Angular/Freights/FreightDetails/FreightDetails';
import AngularCompaniesManager from '../pages/Angular/Companies/AngularCompaniesManager';
import AngularDrivers from '../pages/Angular/Companies/Drivers/AngularDrivers';
import { CompanyProfile } from '../pages/Angular/Companies/Profile';
import AngularCompanyUsers from '../pages/Angular/Companies/Users/AngularCompanyUsers';
import AngularVehicles from '../pages/Angular/Companies/Vehicles/AngularVehicles';
import { FavoriteDrivers } from '../pages/Angular/FavoriteDrivers';
import Dashboard from '../pages/Angular/Freights/Dashboard/Dashboard';
import { Historic } from '../pages/Angular/Freights/Historic';
import MakeFreights from '../pages/Angular/Freights/MakeFreights/makeFreights';
import Progress from '../pages/Angular/Freights/Progress/Progress';
import { RequestFreightPage } from '../pages/Angular/Freights/Request';
import Scheduled from '../pages/Angular/Freights/Scheduled';
import AddCredits from '../pages/Angular/Wallet/AddCredits';
import { Receipt } from '../pages/Angular/Wallet/Receipt';
import { WalletSummary } from '../pages/Angular/Wallet/Summary';
import { PublicFollowFreight } from '../pages/Angular/PublicFollowFreight';

import { Users } from '../pages/Angular/OperationPages/Users';
import OperationDashboard from '../pages/Angular/OperationPages/Companies/Dashboard/OperationDashboard';
import CompanyRegister from '../pages/Angular/Register/CompanyRegister';
import InviteCampaign from '../pages/ReactCSharp/InviteCampaign/InviteCampaign';
import ShareLink from '../pages/ReactCSharp/InviteCampaign/ShareLink';
import { Vehicle } from '../services/angular/types/Order';
import Pendencies from '../pages/ReactCSharp/Pendencies/Pendences';
import { GeofencePage } from '../pages/ReactCSharp/Geofence/Geofence';
import TermoUsuárioCliente from '../pages/termoUsuárioCliente';
import TermoEntregador from '../pages/termoEntregador';
import Privacy from '../pages/privacy';
// import { DotNetMyProfile } from '../pages/ReactCSharp/DotNetMyProfile';
import Operations from '../pages/Angular/OperationPages/Operations/operations';
import Login from '../pages/Angular/Login/AngularLogin';
import { MyProfile } from '../pages/Angular/MyProfile';
import AngularForgot from '../pages/Angular/Forgot/components/AngularForgot';
import { RequesterMultipliers } from '../pages/Angular/OperationPages/RequestMultipliers/RequesterMultipliers';
import { IFoodSettingsPage } from '../pages/Angular/Companies/iFoodSettings';
import { IFoodOrdersPage } from '../pages/Angular/Companies/ifoodOrders';
import { useGetMerchant } from '../services/integration/ifood/useGetMerchant';

export const loadingConfigAtom = atom(true);

const Router: React.FC = () => {
	const {
		isLogged,
		userId,
		user,
		setUser,
		refetchUserCompanies,
		persistGeofenceId,
		userRole,
		company,
	} = useUserState(
		(state) => ({
			isLogged: state.isLogged,
			userId: state.userId,
			user: state.user,
			userRole: state.userRole,
			setUser: state.setUser,
			refetchUserCompanies: state.refetchUserCompanies,
			persistGeofenceId: state.persistGeofenceId,
			company: state.company,
		}),
		shallow
	);
	const { setCancelReasons, setGeofence, setGeofenceId } = useAppState(
		(state) => ({
			setCancelReasons: state.setCancelReasons,
			setGeofence: state.setGeofence,
			setGeofenceId: state.setGeofenceId,
		}),
		shallow
	);

	const [, setIsLoading] = useAtom(loadingConfigAtom);
	const { setConfig } = useStore(appStateStore);

	const { refetch, isFetching } = useQuery(
		['user-companies', userId],
		async () => {
			setIsLoading(true);
			return await api.get(`users/${userId || ''}?include=companies`);
		},
		{
			enabled: !!userId,
			onSuccess: (res) => {
				setUser(res.data);
				setIsLoading(false);
			},
			onError: () => {
				setIsLoading(false);
			},
			cacheTime: 0,
			staleTime: 0,
		}
	);

	useQuery(
		['app-state-geofence'],
		async () => {
			const { data } = await api.get<{
				value: Geofence[];
			}>('/geofence');
			return data;
		},
		{
			enabled: isLogged,
			onSuccess: (res) => {
				// @ts-expect-error
				setGeofence(res);
				persistGeofenceId && setGeofenceId(persistGeofenceId);
			},
			cacheTime: Infinity,
		}
	);

	const { isFetching: fetchingConfig } = useQuery(
		['config'],
		async () => {
			const { data } = await api.get('/config/web');
			return data;
		},
		{
			enabled: isLogged,
			onSuccess: (res) => {
				setConfig(res);
			},
			cacheTime: Infinity,
		}
	);

	useQuery(
		['cancel-reasons'],
		async () => {
			const { data } = await api.get('/order/cancelReasons');
			return data;
		},
		{
			enabled: isLogged,
			onSuccess: (res) => {
				setCancelReasons(res);
			},
			cacheTime: Infinity,
		}
	);
	const { data, isLoading } = useGetMerchant();

	const firstRender = React.useRef(true);

	useEffect(() => {
		if (firstRender.current) {
			firstRender.current = false;
			return;
		}
		void refetch();
	}, [refetchUserCompanies]);

	const { data: companyVehicles } = useQuery(
		['companyVehicles', company?.id],
		async () =>
			await api.get<Vehicle[]>(
				// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
				`/companies/${company?.id}/vehicles?include=category,company`
			),
		{
			enabled: !!company?.id,
		}
	);

	if (isFetching || fetchingConfig) return null;

	return (
		<BrowserRouter>
			<Routes>
				<Route
					element={
						<RequirePermission
							path={
								userRole?.id === Number(appState.userRoles.user.value)
									? '/fretes/pedir'
									: '/operacoes'
							}
							required={isLogged}
						/>
					}
				>
					<Route>
						<Route path="/cadastro-entregador" element={<InviteCampaign />} />
						<Route path="/i/:hash" element={<InviteCampaign />} />

						<Route path="/cadastro-empresa" element={<CompanyRegister />} />
						<Route element={<PublicLayout />}>
							<Route path="*" element={<Navigate to="/login" replace />} />
							<Route path="/login" element={<Login />} />
							<Route path="/esqueci-minha-senha" element={<AngularForgot />} />
						</Route>
					</Route>
				</Route>

				{/* admin */}
				<Route
					element={
						<RequirePermission
							path="/login"
							required={
								!isLogged ||
								userRole?.id === Number(appState.userRoles.user.value)
							}
						/>
					}
				>
					<Route element={<AdminLayout />}>
						<Route path="*" element={<Navigate to="/operacoes" replace />} />

						<Route path="/operacoes" element={<Operations />} />

						<Route path="/pedidos">
							<Route index element={<OrdersPage />} />
							<Route path=":orderId" element={<OrderDetails />} />
							<Route path="acompanhar/:orderId" element={<Follow />} />
							<Route path="editar/:orderId" element={<EditOrder />} />
						</Route>
						<Route path="/monitoramento" element={<Monitoring />} />
						<Route path="/financeiro">
							<Route path="receber" element={<Earnings />} />
							<Route path="pagar" element={<Charges />} />
							{/* <Route path="diarias" element={<DailyPayments />} /> */}
						</Route>

						<Route path="/usuarios">
							<Route index element={<Users />} />
							<Route path=":id" element={<EditUser />} />
						</Route>

						<Route path="/veiculos">
							<Route index element={<Vehicles />} />
							<Route path=":id" element={<AddVehicle />} />
						</Route>

						<Route path="/empresas">
							<Route index element={<Companies />} />
							<Route path=":id" element={<AngularNewCompanyRegister />} />
							<Route path="dashboard/:id" element={<OperationDashboard />} />
							
						</Route>

						<Route path="/administracao">
							<Route path="notificacoes" element={<Notifications />} />
							<Route path="pop-informativo" element={<InformativePopUp />} />

							<Route
								element={
									<RequirePermission
										required={!isSuperAdmin(userRole)}
										path="/operacoes"
									/>
								}
							>
								<Route path="auditoria" element={<Audit />} />
								<Route path="transacoes" element={<Transactions />} />
								<Route path="geofences" element={<GeofencePage />} />
							</Route>
						</Route>
						<Route path="/configuracoes">
							<Route path="veiculos" element={<ConfigVehicles />} />
							<Route path="veiculos/:id" element={<EditCategory />} />
							<Route path="sistema" element={<ConfigSystem />} />
							<Route
								path="multiplicadores"
								element={<RequesterMultipliers />}
							/>
						</Route>
					</Route>
				</Route>

				{/* user */}
				<Route
					element={
						<RequirePermission
							path="/login"
							required={
								!isLogged ||
								userRole?.id !== Number(appState.userRoles.user.value)
							}
						/>
					}
				>
				<Route
					path="/pendencias"
					element={
						<RequirePermission
						path="/"
						required={
							!!(
								user?.driverStatus === 0 ||
								(company?.approved &&
									user?.driverStatus === 3 &&
									companyVehicles?.data.some(
										(vehicle: Vehicle) => vehicle.approved
									)
								)
							)
						}
						>
							<Pendencies />
						</RequirePermission>
					}
					/>


					<Route path="*" element={<Navigate to="/pendencias" replace />} />

					<Route
						element={
							<RequirePermission
								path="/pendencias"
								required={
									user?.driverStatus !== 0 &&
									user?.driverStatus !== 3 &&
									!!(
										!company?.approved ||
										!companyVehicles?.data?.some(
											(vehicle: Vehicle) => vehicle.approved
										)
									)
								}
							>
								<AdminLayout />
							</RequirePermission>
						}
					>
						<Route path="*" element={<Navigate to="/fretes/pedir" replace />} />
						<Route path="iFood">
							<Route
								path="orders"
								element={
									<RequirePermission
										path="/negocios/gerenciador"
										required={!data && !isLoading}
									>
										<IFoodOrdersPage />
									</RequirePermission>
								}
							/>
							<Route
								path="settings"
								element={
									<RequirePermission
										path="/negocios/gerenciador"
										required={!data && !isLoading}
									>
										<IFoodSettingsPage />
									</RequirePermission>
								}
							/>
						</Route>
						<Route path="/fretes">
							<Route
								path="pedir"
								element={
									<RequirePermission
										path="/negocios/gerenciador"
										required={!canRequestFreight(company)}
									>
										<RequestFreightPage />
									</RequirePermission>
								}
							/>
							<Route path="historico" element={<Historic />} />
							<Route path="em-andamento" element={<Progress />} />
							<Route path="agendados" element={<Scheduled />} />
							<Route path="detalhes/:orderId" element={<OrderDetails />} />
							<Route
								path="rascunhos"
								element={
									<RequirePermission
										path="/negocios/gerenciador"
										required={!canRequestFreight(company)}
									>
										<Draft />
									</RequirePermission>
								}
							/>
							<Route
								path="dashboard"
								element={
									<RequirePermission
										path="/negocios/gerenciador"
										required={!canSeeDashboard(company)}
									>
										<Dashboard />
									</RequirePermission>
								}
							/>
	
							<Route path="acompanhar/:orderId" element={<Follow />} />

							<Route
								path=""
								element={<Navigate to="/negocios/gerenciador" replace />}
							/>
						</Route>
						<Route
							path="/fazer-fretes"
							element={
								<RequirePermission
									path="/negocios/gerenciador"
									required={!canManageCompany(company)}
								>
									<MakeFreights />
								</RequirePermission>
							}
						/>
						<Route
							path="/carteira"
							element={
								<RequirePermission
									path="/negocios/gerenciador"
									required={!canSeeWallet(company)}
								/>
							}
						>
							<Route path="resumo" element={<WalletSummary />} />
							<Route
								path="comprar-creditos"
								element={
									<RequirePermission
										path="/negocios/gerenciador"
										required={!canBuyCredits(company)}
									>
										<AddCredits />
									</RequirePermission>
								}
							/>
							<Route
								path="recebimento"
								element={
									<RequirePermission
										path="/negocios/gerenciador"
										required={!canSeeReceipt(company)}
									>
										<Receipt />
									</RequirePermission>
								}
							/>

							<Route
								path=""
								element={<Navigate to="/negocios/gerenciador" replace />}
							/>
						</Route>
						<Route path="/negocios">
							<Route path="gerenciador" element={<AngularCompaniesManager />} />
							<Route path="novo" element={<AngularNewCompanyRegister />} />

							<Route
								element={
									<RequirePermission
										path="/negocios/gerenciador"
										required={!canManageCompany(company)}
									/>
								}
							>
								<Route path="perfil" element={<CompanyProfile />} />
								<Route path="usuarios" element={<AngularCompanyUsers />} />
							</Route>

							<Route
								element={
									<RequirePermission
										path="/negocios/gerenciador"
										required={!canManageCompanyDrivers(company)}
									/>
								}
							>
								<Route path="veiculos" element={<AngularVehicles />} />
								<Route path="veiculos/:id" element={<AddVehicle />} />
								<Route path="motoristas" element={<AngularDrivers />} />
							</Route>

							<Route
								path=""
								element={<Navigate to="/negocios/gerenciador" replace />}
							/>
						</Route>
						<Route path="/minha-conta" element={<MyProfile />} />
						<Route
							path="/motoristas-favoritos"
							element={
								<RequirePermission
									path="/negocios/gerenciador"
									required={!canSeeFavorites(company)}
								>
									<FavoriteDrivers />
								</RequirePermission>
							}
						/>
					</Route>
				</Route>

				<Route
					path="/trk/:locationId/:token"
					element={<PublicFollowFreight />}
				/>

				<Route
					path="/termos-de-uso"
					element={
						company?.type !== 'Motorista' ? (
							<TermoUsuárioCliente />
						) : (
							<TermoEntregador />
						)
					}
				/>

				<Route path="/termos-usuarios" element={<TermoUsuárioCliente />} />
				<Route path="/termos-entregadores" element={<TermoEntregador />} />
				<Route path="/politica-privacidade" element={<Privacy />} />

				<Route path="/convite">
					<Route path=":hash" element={<ShareLink />} />
					<Route path="" element={<ShareLink />} />
				</Route>
			</Routes>
		</BrowserRouter>
	);
};

export default Router;
